body {
  font-family: 'Inter', sans-serif;
  color: #18191F !important;
}

.py-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.min-vh-90 {
  min-height: 90vh;
}

.fs-6 {
  font-size: 14px;
}

h1.display-1 {
  font-size: 72px !important;
  font-weight: 800 !important;
  line-height: 98px;
}

h1.display-2 {
  font-size: 60px !important;
  font-weight: 800 !important;
  line-height: 64px;
}

.btn-circle.btn-sm {
  width: 32px;
  height: 32px;
  padding: 6px 0;
  text-align: center;
}

.link-light:hover {
  color: #F1E4FF;
}